<template>
  <div class="main">
    <div class="container">
      <div class="news_header">
        <div class="title">Privacy policy</div>
      </div>
      <div class="news_detail">
        <div class="text">
          This global privacy policy explains how we handle your personal data,
          including the types of personal data we collect about you, how your
          personal data may be used and when it may be disclosed, how we protect
          your personal data and the rights you have in relation to the
          processing of your personal data. ECI respects your privacy, and we
          are committed to protecting your personal data in accordance with
          applicable data privacy laws and our internal policies.<br />
          In the event of conflict between a translated version of this global
          privacy policy and the English version, the English version shall
          prevail.
          <br /><br />

          <div class="t2">1. Who is the controller of my personal data?</div>
          Unless we notify you otherwise, ECI is the controller of your personal
          data as it is the entity who alone or jointly determines how or why
          your personal data is being processed. Our affiliates may be the
          controller of your personal data in certain circumstances depending on
          our relationship, and the services we are providing to you. A list of
          our group of affiliates can be found here.
          <br /><br />
          <div class="t2">2. How do we collect your personal data?</div>
          We use different methods to collect personal data from and about you,
          including: -<br />
          Collection directly from you<br />
          Most of the personal data we process about you comes directly from
          you. You may give us your name and contact details when filling in
          forms or corresponding with us by email, phone, mail, in person or
          otherwise including when you: -<br />
          subscribe to our services, products, or email alerts; and<br />
          buy or express an interest in our products and services (including
          attending an event).<br />

          <div class="t2">Automatic collection</div>
          We automatically collect technical information when you use our
          websites, for example: IP address, log-in data, browser type, time
          zone setting and location and plug-in types when you interact with our
          websites. We also collect data through cookies. Please see our cookies
          policy for further details. Collection from other sources<br />
          We may collect information about you from third parties and public
          sources (such as public databases and social media websites). This is
          to ensure we have accurate, up to date or necessary information for us
          to communicate with you or to provide the best possible service. The
          types of information we may collect includes personal details
          (including name, age, gender, and other demographic data) and contact
          information (including postal address and telephone numbers).
          <br /><br />

          <div class="t2">
            3. For which purposes and on which legal basis do we process your
            personal data?
          </div>
          We have set out below a description of all the ways we plan to use
          your personal data and which legal basis we rely on to do so. We have
          also identified what our legitimate interests are, where appropriate.
          Legal basis <br />
          Necessary for the compliance with a legal obligation to which we are
          subject.<br />
          Necessary for the performance of a contract to which you are a party.
          <br />
          Justified on the basis of our legitimate interest in ensuring the
          proper functioning of our business operations.<br />
          Justified on the basis of our legitimate interest in ensuring that we
          can conduct and increase our business. <br />
          Purpose <br />
          Complying with legal requirements. <br />
          Managing our contractual relationship with you. <br /><br />

          <div class="t2">
            4. How do we use personal data for direct marketing purposes?
          </div>
          We may send you marketing communications to keep you up to date with
          our products and services, including those we think may be of interest
          to you.<br />
          Do we send targeted e-mails?<br />
          We send targeted electronic direct marketing to business contacts at
          our clients or other companies with whom we want to develop or
          maintain a business relationship in accordance with applicable
          marketing laws. Our electronic direct marketing typically includes web
          beacons, cookies, and similar technologies that allow us to know
          whether you open, read, or delete the message, and links you may
          click. When you click a link in an electronic direct marketing
          message, we will also use a cookie to log what pages you view and what
          content you download from our websites, even if you are not registered
          at or signed into our site. Please see our cookies policy for further
          details.<br />
          Do we maintain Customer Relationship Management (CRM) databases?<br />
          Like most companies, ECI uses customer relationship management (CRM)
          database technology to manage and track our marketing efforts. Our CRM
          databases include personal data belonging to business contacts at our
          clients and other companies with whom we already have a business
          relationship or want to develop one. The personal data used for these
          purposes includes relevant business information such as: contact data,
          publicly available information (e.g. board membership, published
          articles, press releases, your public posts on social media sites if
          relevant for business purpose), your responses to targeted electronic
          direct marketing (including web activity following links from our
          e-mails), website activity of registered users of our website and
          other business information included by ECI professionals based on
          their personal interactions with you.<br />
          Do we combine and analyze personal data?<br />
          We may combine data from publicly available sources, and from our
          different e-mail, website, and personal interactions with you (this
          includes information collected across our different websites such as
          our careers and corporate sites and information collected when you
          sign-up or log on to our sites or connect to our sites using your
          social media credentials). We combine this data to better assess your
          experience with ECI and to perform the other activities described
          throughout this privacy policy. What are your rights regarding
          marketing communications?<br />
          You can elect to not receive marketing communications from us by, or
          by utilizing opt-out mechanisms in e-mails we send to you. You can
          also object to receiving marketing communications or request that your
          personal data be removed from our CRM databases by contacting us at
          international@eciawards.org.
          <br /><br />

          <div class="t2">5. What about sensitive data?</div>
          We do not generally seek to collect sensitive data (also known as
          special categories of personal data) from individuals, and we request
          that you please do not submit sensitive data to us. In the limited
          cases where we do seek to collect such data, we will do this in
          accordance with data privacy law requirements and/or ask for your
          consent.<br />
          The term "sensitive data" refers to the various categories of personal
          data including racial or ethnic origin, political opinions, religious,
          philosophical, or other similar beliefs, membership of a trade union,
          physical or mental health, biometric or genetic data, sexual life or
          orientation, or criminal convictions and offences (including
          information about suspected criminal activities).

          <br /><br />
          <div class="t2">6. What about persons 18 or under?</div>
          We are concerned to protect the privacy of children. We do not
          knowingly contact or collect information from persons under the age of
          18. The website is not intended to solicit information of any kind
          from persons under the age of 18.<br />
          If you believe that we have received information pertaining to or from
          persons under the age of 18 please contact us at contacting us at
          international@eciawards.org. If we become aware that a person under
          the age of 18 has provided us with personal information, we will take
          steps to delete such information.
          <br /><br />

          <div class="t2">
            7. Will we share your personal data with third parties?
          </div>
          We may share personal data with the following categories of
          recipients:<br />
          Our affiliates, so they can send you communications about their
          products and services (as further described in section 3 above)<br />
          Our third-party service providers, including IT service providers,
          marketing automation, solutions and analytics providers, marketing
          agencies, professional advisors, and<br />
          Our selected partners, representatives and sponsors, for example we
          may share attendee lists with event sponsors so that they can better
          plan their sponsorship to enhance attendee experience and, where
          applicable, to choose invitees to private briefings, meetings or
          dinners, or we may make available contact details to create marketing
          opportunities in certain industries and markets.<br />
          We may also share your personal data with public and governmental
          authorities if we are required to do so, or with third parties in
          connection with potential corporate or commercial transaction.<br />
          Before we share your personal data with any third parties, we take the
          necessary steps to ensure that your personal data will be given
          adequate protection in accordance with relevant data privacy laws and
          ECI’s internal policies.<br />
          <br />

          <div class="t2">
            8. How long will your personal data be retained by us?
          </div>
          We retain your information for no longer than necessary for the
          purpose for which it was collected. We maintain specific records
          management and retention policies and procedures, so that in
          determining how long your information will be stored, we will
          consider:<br />
          the purpose(s) and use of your information both now and in the
          future.<br />
          our global legal and contractual obligations; and<br />
          what information we need to manage your relationship with us and to
          develop and deliver our products and services.<br /><br />
          <div class="t2">9. Do we transfer your data internationally?</div>
          As we are a global organization with a presence in a number of
          jurisdictions and countries, we may transfer your personal data to our
          affiliates internationally. <br />
          To ensure that such transfers are in accordance with applicable law
          and carefully managed to protect your privacy rights, any
          inter-company transfers of personal data are covered by agreement(s)
          which contractually oblige(s) us to ensure adequate and consistent
          levels of protection wherever personal data is transferred within our
          group of companies.<br />
          We may also share your personal data with third parties (see section
          6), Whenever we transfer your personal data outside, we ensure a
          similar degree of protection is afforded it by ensuring appropriate
          safeguards are implemented, for example we may use specific contracts
          approved by regional permission.<br />
          Please contact us if you would like further information on the
          mechanism used by us when transferring your personal data outside.

          <br /><br />
          <div class="t2">10. Data security</div>
          We maintain organizational, physical and technical security
          arrangements for all the personal data we hold. We have protocols,
          controls and relevant policies, procedures and guidance to maintain
          these arrangements taking into account the risks associated with the
          categories of personal data and the processing we undertake, including
          as appropriate: <br />
          The pseudonymization and encryption of personal data.<br />
          The ability to ensure the ongoing confidentiality, integrity,
          availability and resilience of processing systems and services.<br />
          The ability to restore the availability and access to personal data in
          a timely manner in the event of a physical or technical incident.<br />
          A process for regularly testing, assessing and evaluating the
          effectiveness of technical and organizational measures for ensuring
          the security of personal data.

          <br /><br />
          <div class="t2">
            11. Which rights do you have with respect to the processing of your
            personal data?
          </div>
          If you are based in EU region, you may be entitled under certain
          circumstances to: -<br />
          Request access to the personal data we process about you: this right
          entitles you to know whether we hold personal data about you and, if
          we do, to obtain information on and a copy of that personal data.<br />
          Request a rectification of your personal data: this right entitles you
          to have your personal data corrected if it is inaccurate or
          incomplete.<br />
          Object to the processing of your personal data: this right entitles
          you to request that ECI no longer processes your personal data.<br />
          Request the erasure of your personal data: this right entitles you to
          request the erasure of your personal data, including where such
          personal data would no longer be necessary to achieve the purposes.<br />
          Request the restriction of the processing of your personal data: this
          right entitles you to request that ECI only processes your personal
          data in limited circumstances, including with your consent.<br />
          Request portability of your personal data: this right entitles you to
          receive a copy (in a structured, commonly used and machine-readable
          format) of personal data that you have provided to ECI, or request ECI
          to transmit such personal data to another data controller.<br />
          Request not to be subject to any decision that significantly affects
          you being taken by automated processing, including profiling.<br />
          If you are based in CALIFORNIA, you have many of the rights set out at
          section 11 and you are entitled under certain circumstances to<br />
          Request access to certain information about the personal data we have
          shared with third parties for direct marketing purposes.<br />
          Please contact us at international@eciawards.org to exercise any of
          these rights. We will process any request in line with any local laws
          and our policies and procedures. We may ask you for additional
          information to confirm your identity and for security purposes, before
          disclosing information requested to you.

          <br /><br />
          <div class="t2">
            12. What if you have questions or want further information?
          </div>
          Any questions regarding this notice and our privacy practices should
          be sent using international@eciawards.org or by writing to Director of
          Compliance.<br />
          If you wish to make a complaint about how we use your information,
          please contact us using international@eciawards.org and we will do our
          best to help. If you are located in the EEA, you have the right to
          lodge a complaint with the appropriate data protection supervisory
          authority in your country. We would, however, appreciate the chance to
          deal with your concerns before you approach your local supervisory
          authority so please contact us in the first instance.<br />
          About our Representatives network<br />
          ECI has a network of country Representatives across the globe. They
          serve as champions for creativity in their respective corners of the
          world. Our Representatives support local agencies and clients with
          making the most of their ECI experience, before ECI , in ECI and
          afterwards.<br />
          They often report from ECI day-by-day and bring a slice of ECI home
          with them, producing local events, Lions Nights and local awards
          ceremonies.<br />
          How the Representatives help ECI <br /><br />
          1.Nominating jury members<br />
          Each year, Representatives identify their country's best creative
          talent and nominate exemplary industry members for places in the ECI
          Juries, ensuring the most qualified, balanced judging panels.
          <br />2.Hosting Local Award Shows<br />
          If you didn't get a chance to come to ECI or catch all the winners
          while you were there, your Representative will get you up to speed.
          Screenings of the work following the final year give you a chance to
          check out all the winning work.
          <br />3.Screenings of the work<br />
          If you didn't get a chance to come to ECI or catch all the winners
          while you were there, your Representative will get you up to speed.
          Screenings of the work following the final year give you a chance to
          check out all the winning work.
          <br />4.Answer questions<br />
          Your Representative is available to answer questions, help you to
          register as an attendee or enter your work. If they don’t know the
          answer, they can refer you to the best people to help in the ECI
          festival team.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'text',
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/news.scss';
</style>
